@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
.login {
  width: 100%;
  height: 100%;
  background: white no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 640px) {
    .login {
      padding: 40px 10px;
      box-sizing: border-box;
      height: auto; } }
  .login .content-login {
    max-width: 540px;
    text-align: center;
    margin-top: 10px; }
    .login .content-login img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px; }
    .login .content-login .box-login {
      margin-bottom: 30px;
      margin-top: 5px;
      background: #202331;
      border-radius: 20px;
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      text-align: left; }
      @media (max-width: 640px) {
        .login .content-login .box-login {
          padding: 20px; } }
      .login .content-login .box-login p.tit {
        font-size: 30px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 5px; }
      .login .content-login .box-login p {
        font-size: 16px;
        color: #8b8d97; }
      .login .content-login .box-login .main-form {
        margin: 40px 0; }
        .login .content-login .box-login .main-form input[type='text'],
        .login .content-login .box-login .main-form input[type='password'] {
          width: 100%;
          height: 60px;
          border: 0;
          font-size: 14px;
          padding: 0 20px;
          box-sizing: border-box;
          border-radius: 0 0 6px 6px;
          font-weight: 400; }
          .login .content-login .box-login .main-form input[type='text'].first,
          .login .content-login .box-login .main-form input[type='password'].first {
            border-radius: 6px 6px 0 0;
            border-bottom: solid 1px #ccc; }
          .login .content-login .box-login .main-form input[type='text']::-webkit-input-placeholder,
          .login .content-login .box-login .main-form input[type='password']::-webkit-input-placeholder {
            font-size: 14px;
            font-weight: 400; }
          .login .content-login .box-login .main-form input[type='text']::-moz-placeholder,
          .login .content-login .box-login .main-form input[type='password']::-moz-placeholder {
            font-size: 14px;
            font-weight: 400; }
          .login .content-login .box-login .main-form input[type='text']::-ms-input-placeholder,
          .login .content-login .box-login .main-form input[type='password']::-ms-input-placeholder {
            font-size: 14px;
            font-weight: 400; }
          .login .content-login .box-login .main-form input[type='text']::placeholder,
          .login .content-login .box-login .main-form input[type='password']::placeholder {
            font-size: 14px;
            font-weight: 400; }
        .login .content-login .box-login .main-form input[type='checkbox'] {
          width: 18px;
          height: 18px;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          padding: 0;
          border: solid 2px #929292;
          border-radius: 5px;
          background-position: center;
          -webkit-transition: all ease 0.3s;
          transition: all ease 0.3s; }
          .login .content-login .box-login .main-form input[type='checkbox']:checked {
            background: url("http://webcol.systems/corteva/inbound/comex/img/icons/icon-check.png") no-repeat center #055aab;
            border: solid 2px #055aab; }
        .login .content-login .box-login .main-form button {
          margin-left: auto;
          margin-right: auto;
          width: 50%;
          display: block;
          background: #055aab;
          color: #fff;
          height: 50px;
          font-size: 17px;
          border-radius: 8px;
          border: solid 1px #055aab;
          -webkit-transition: 0.2s;
          transition: 0.2s; }
          .login .content-login .box-login .main-form button:hover {
            background: rgba(5, 90, 171, 0.2); }
        .login .content-login .box-login .main-form .row {
          margin: 20px 0 40px;
          display: flex;
          justify-content: space-between;
          align-items: center; }
          @media (max-width: 640px) {
            .login .content-login .box-login .main-form .row {
              flex-wrap: wrap;
              justify-content: center; } }
          .login .content-login .box-login .main-form .row label {
            font-size: 14px;
            color: #8b8d97;
            display: flex;
            align-items: center;
            cursor: pointer; }
            .login .content-login .box-login .main-form .row label input {
              margin-right: 10px; }
            @media (max-width: 640px) {
              .login .content-login .box-login .main-form .row label {
                margin-bottom: 10px; } }
          .login .content-login .box-login .main-form .row .esqueciminhasenha {
            font-size: 14px;
            color: #8b8d97;
            text-decoration: underline;
            cursor: pointer; }
          .login .content-login .box-login .main-form .row .redText {
            color: #ff5757; }

.center {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  width: 100%; }
  @media (max-width: 1099px) {
    .center {
      width: 92%; } }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-Medium.eot") format("embedded-opentype"), url("/css/font/CircularStd-Medium.woff") format("woff"), url("/css/font/CircularStd-Medium.ttf") format("truetype"), url("/css/font/CircularStd-Medium.svg#CircularStd-Medium") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-Black.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-Black.woff") format("woff"), url("/css/font/CircularStd-Black.ttf") format("truetype"), url("/css/font/CircularStd-Black.svg#CircularStd-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-BlackItalic.woff") format("woff"), url("/css/font/CircularStd-BlackItalic.ttf") format("truetype"), url("/css/font/CircularStd-BlackItalic.svg#CircularStd-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-Bold.woff") format("woff"), url("/css/font/CircularStd-Bold.ttf") format("truetype"), url("/css/font/CircularStd-Bold.svg#CircularStd-Bold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-BoldItalic.woff") format("woff"), url("/css/font/CircularStd-BoldItalic.ttf") format("truetype"), url("/css/font/CircularStd-BoldItalic.svg#CircularStd-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-Book.woff") format("woff"), url("/css/font/CircularStd-Book.ttf") format("truetype"), url("/css/font/CircularStd-Book.svg#CircularStd-Book") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'CircularStd';
  src: url("/css/font/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("/css/font/CircularStd-BookItalic.woff") format("woff"), url("/css/font/CircularStd-BookItalic.ttf") format("truetype"), url("/css/font/CircularStd-BookItalic.svg#CircularStd-BookItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

/* SCSS Mixins*/
html {
  margin: 0;
  padding: 0;
  border: 0;
  overflow-x: hidden;
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

a[href^="tel"] {
  color: inherit;
  text-decoration: none; }

article, aside, dialog, figure, footer, header, hgroup, nav, section, div {
  display: block; }

button, input, input, select {
  outline: none;
  font-family: "Open Sans", sans-serif; }

input[type="submit"], button {
  cursor: pointer;
  border: 0;
  outline: none; }

li, a, input {
  outline: none; }

body {
  line-height: 1;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  overflow: hidden; }

ol, ul, li {
  list-style: none; }

table {
  border-collapse: separate;
  border-spacing: 0; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

a {
  text-decoration: none; }

.clearfix:after {
  clear: both;
  display: block;
  content: ''; }

.reset {
  clear: both;
  display: block; }

.error {
  color: #9a0000 !important; }

@media print {
  body {
    font-size: 9px; } }

html {
  height: 100%; }

body {
  line-height: 1;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  background: #ECECEC;
  min-height: 100%; }
  @media (max-width: 640px) {
    body {
      font-size: 9px; } }

.box-loading {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #055eb3;
  margin: -3px 0 0 -3px; }

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px; }

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px; }

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px; }

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px; }

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px; }

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px; }

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px; }

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Toastify__toast {
  font-size: 14px; }

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%; }

.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px; }
  .page-header form input {
    background: none;
    border: 0;
    padding: 0 0 0 30px;
    border-bottom: solid 1px #000;
    height: 30px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #8B8D97;
    box-sizing: border-box; }
    .page-header form input.date {
      background: url(/img/icons/icon-calendar.png) no-repeat left;
      width: 130px;
      margin-left: 20px; }
    .page-header form input.search {
      background: url(/img/icons/icon-search.png) no-repeat left;
      width: 220px;
      margin-left: 20px; }
  .page-header .last-wrap {
    display: flex;
    align-items: center; }
  .page-header .exportexcel {
    box-sizing: border-box;
    height: 40px;
    border-radius: 6px;
    padding: 0 10px;
    background: #f9f9f9;
    -webkit-transition: all ease .2s;
    transition: all ease .2s;
    margin-right: 10px;
    overflow: hidden;
    position: relative; }
    .page-header .exportexcel span {
      position: absolute;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
      -webkit-transition: all ease .2s;
      transition: all ease .2s;
      margin-left: -10px; }
    .page-header .exportexcel img {
      height: 25px;
      -webkit-transition: all ease .2s;
      transition: all ease .2s; }
    .page-header .exportexcel p {
      font-size: 12px;
      line-height: 40px;
      -webkit-transition: all ease .4s;
      transition: all ease .4s;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      color: #999; }
    .page-header .exportexcel:hover {
      background: #408c44; }
      .page-header .exportexcel:hover span {
        -webkit-transform: translateY(0);
                transform: translateY(0); }
      .page-header .exportexcel:hover p {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
        opacity: 1; }
  .page-header .btnvoltar {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .page-header .btnvoltar img {
      margin-right: 10px; }
    .page-header .btnvoltar p {
      font-size: 14px;
      color: #000; }

.btn-filter-nfs {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 6px;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s; }
  .btn-filter-nfs img {
    margin-right: 10px; }
  .btn-filter-nfs .icon-filter {
    margin-right: 10px; }
    .btn-filter-nfs .icon-filter span {
      display: block;
      width: 17px;
      height: 2px;
      background: #000;
      margin-bottom: 4px;
      position: relative; }
      .btn-filter-nfs .icon-filter span:before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        left: 2px;
        border-radius: 50%;
        width: 2.5px;
        height: 2px;
        border: solid 2px #000;
        background: #ECECEC;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s; }
      .btn-filter-nfs .icon-filter span:nth-child(2n):before {
        left: 10px;
        -webkit-transition: all ease 0.4s;
        transition: all ease 0.4s; }
      .btn-filter-nfs .icon-filter span:last-child:before {
        -webkit-transition: all ease 0.6s;
        transition: all ease 0.6s; }
  .btn-filter-nfs:hover, .btn-filter-nfs.active {
    background: #fff; }
    .btn-filter-nfs:hover .icon-filter span:before, .btn-filter-nfs.active .icon-filter span:before {
      left: 10px; }
    .btn-filter-nfs:hover .icon-filter span:nth-child(2n):before, .btn-filter-nfs.active .icon-filter span:nth-child(2n):before {
      left: 2px; }

.filter-box {
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  border-left: solid 7px #4C5062;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: none;
  -webkit-transform: translate(0, -20px);
          transform: translate(0, -20px);
  opacity: 0;
  z-index: 9; }
  .filter-box.active {
    display: block;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1; }
  .filter-box:before {
    content: '';
    position: absolute;
    top: -6px;
    right: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #ffffff transparent; }
  .filter-box form {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .filter-box form label {
      display: block;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 10px;
      text-align: left; }
    .filter-box form .boxurgente {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .filter-box form .boxurgente label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-right: 20px; }
        .filter-box form .boxurgente label input {
          margin-right: 10px; }
    .filter-box form .boxstatus {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .filter-box form .boxstatus label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-right: 10px; }
        .filter-box form .boxstatus label input {
          margin-right: 10px; }
    .filter-box form input, .filter-box form select {
      width: 240px;
      height: 50px;
      border: solid 1px #C2C2C2;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 300;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s; }
      .filter-box form input:focus, .filter-box form select:focus {
        border: solid 1px #292D41; }
      .filter-box form input.date, .filter-box form select.date {
        width: 180px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAOxJREFUOI210jtKg0EUBeDPB9gZQQMGDKSwUJvU6eIiTCfYRDfgBlyCvSswO7Cys7bQRougldi4gIgWub+OyYz8Fh44cOfM3HMfDP+AJaxn9I24m3tcYQU9HOIUz+gkPMcWPvCCd1iI5B5GeEQzeD9TbA+vwW0c4Ka6vMBxxLsYZkYYYifik8ixHEIDLfTj/JDEEm0z2MJqagAD7Gcq59DEXSqMTGeqiwEuZzuosIZuIfEWb6lQMjgrGBzVMRibX2ARixmtb/pZKo5/M8h1cO37g9Xu4El5cTl0I+cLbVxh4mf7OU7ibfsPBcv4BIhSLSmDGit6AAAAAElFTkSuQmCC) no-repeat 90%; }
    .filter-box form .react-datepicker__input-container input {
      width: 180px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAOxJREFUOI210jtKg0EUBeDPB9gZQQMGDKSwUJvU6eIiTCfYRDfgBlyCvSswO7Cys7bQRougldi4gIgWub+OyYz8Fh44cOfM3HMfDP+AJaxn9I24m3tcYQU9HOIUz+gkPMcWPvCCd1iI5B5GeEQzeD9TbA+vwW0c4Ka6vMBxxLsYZkYYYifik8ixHEIDLfTj/JDEEm0z2MJqagAD7Gcq59DEXSqMTGeqiwEuZzuosIZuIfEWb6lQMjgrGBzVMRibX2ARixmtb/pZKo5/M8h1cO37g9Xu4El5cTl0I+cLbVxh4mf7OU7ibfsPBcv4BIhSLSmDGit6AAAAAElFTkSuQmCC) no-repeat 90%; }
    .filter-box form input[type="checkbox"] {
      width: 18px !important;
      height: 18px;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      padding: 0;
      border: solid 2px #929292;
      background-position: center; }
      .filter-box form input[type="checkbox"]:checked {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABHNCSVQICAgIfAhkiAAAAHRJREFUGJVjYCAAGHFJaOra1zL8Z4pnwinJwNT0n4HhBSNM5Q8GJuv7V/a+hEv+Zzj6h4ExngVqkTLH/39HNbQdNzIwMBTBJO9c2XeX+c2rh4dExeX/MTAyBjMyMlgiSzIwMDAwMzAwMMAU/f/PyIYsSRQAAFKINuzWI84RAAAAAElFTkSuQmCC) no-repeat center;
        border: solid 2px #292D41; }
    .filter-box form .nfs input {
      margin-right: 10px; }
    .filter-box form .nfs label {
      display: flex;
      align-items: center;
      font-size: 15px; }
      .filter-box form .nfs label:last-child {
        margin-bottom: 0; }
    .filter-box form.formoperacional .row {
      margin-bottom: 20px; }
      .filter-box form.formoperacional .row button {
        width: 100%; }
    .filter-box form.formoperacional .item span {
      display: flex;
      justify-content: space-between; }
      .filter-box form.formoperacional .item span .react-datepicker-wrapper {
        width: 48%; }
    .filter-box form.formoperacional input {
      width: 100%; }
  .filter-box .btn-status {
    width: 50px;
    height: 50px;
    background-color: #8B8D97;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAAABHNCSVQICAgIfAhkiAAAAW9JREFUSIm1lTFLHFEUhb+3bCNbWiwEO8tAVsg2RoIW2fwDO5Mq+Q9JJQS0ib2Flf4BbUUIiWAat4i7kDIEJKBgSi2U+Fn4FjbDzJvdWT3Ng7ln5pxz331vIAH1kw+Hv+qTekoQmI/rd+CmhJvCLDADPE2y1IPorjmBGOpG/E6nlinMqVeDHgCvYumsQgv/qe+z4tmWPgOmgF/A6QShGkAbeAlspQQH2AwhbFRVU1vAj7xaTe0OtXA7Pv9coYWqX8vM1IDnVZPkYHEUQYBemBBAfxRHtXLK/1D31b1x36skqH4AXnM/yY8rqC4Ba8A58LaqIHG6Tko4TfUiHuaFAk4vTjpqq2CKO4UJ1XX1jVoHdoFpYDWEcFQx2zXwuzCh2o+1w7h+UUPCYF7CnSwv9bdYAY65v57+AMshBMtixMPfSBEK91B9p96qL0YQ6uXs2cexBMfBcEtTCJF0CXQn1GwDjXjrJJ19K7yKx8fPMld3A0IdXCBpj6kAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    cursor: pointer; }
  .filter-box .btn {
    height: 50px; }
  @media (max-width: 950px) {
    .filter-box form {
      flex-wrap: wrap; } }
  @media (max-width: 720px) {
    .filter-box form .nfs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: solid 1px #ccc;
      margin-bottom: 10px; }
      .filter-box form .nfs label {
        margin-bottom: 0; }
    .filter-box form .item {
      width: 100%;
      margin-bottom: 10px; }
      .filter-box form .item input[type="text"] {
        height: 40px;
        width: 100%; }
    .filter-box form .btn {
      width: 100%; } }

.btn {
  border-radius: 6px;
  background: #1ABC9C;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  border: solid 1px #1ABC9C;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  .btn.remove, .btn.excluir {
    background: #FF4E50;
    border: solid 1px #FF4E50; }
    .btn.remove:hover, .btn.excluir:hover {
      color: #FF4E50;
      background: transparent;
      border: solid 1px #FF4E50; }
  .btn.cancel {
    background: #8B8D97;
    border: solid 1px #8B8D97; }
    .btn.cancel:hover {
      color: #8B8D97;
      background: transparent;
      border: solid 1px #8B8D97; }
  .btn:hover {
    color: #1ABC9C;
    background: transparent;
    border: solid 1px #1ABC9C; }
  .btn.loading {
    display: flex;
    align-items: center;
    float: left;
    padding: 0 20px; }
    .btn.loading .loadingbtn {
      display: block; }
    .btn.loading:hover {
      color: #fff;
      background: #1ABC9C;
      border: solid 1px #1ABC9C; }

h1 {
  font-size: 20px;
  color: #292D41;
  font-weight: 400;
  align-items: center;
  margin: 20px; }
  h1 img {
    margin: 20px; }

.pagination {
  display: flex;
  margin: 20px 0 40px; }
  .pagination .arrow {
    padding: 0 15px;
    background: #fff;
    height: 45px;
    border: solid 1px #ddd;
    -webkit-transition: .2s;
    transition: .2s;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-right: none;
    cursor: pointer; }
    .pagination .arrow.prev {
      border-radius: 6px 0 0 6px;
      border-right: none; }
    .pagination .arrow.next {
      border-radius: 0 6px 6px 0;
      border-right: solid 1px #ddd; }
    .pagination .arrow:hover, .pagination .arrow.active {
      background: #1abc9c;
      color: #fff; }
    .pagination .arrow:disabled {
      background: #e6e6e6;
      color: #999;
      cursor: auto;
      opacity: 0.5; }
      .pagination .arrow:disabled:hover {
        background: #e6e6e6;
        color: #999; }

.main-header {
  width: 100%;
  height: 70px;
  background: white;
  margin-bottom: 3px;
  margin-top: 3px; }
  .main-header .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
  .main-header .main-nav {
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-around; }
    @media (max-width: 520px) {
      .main-header .main-nav {
        display: none; } }
    .main-header .main-nav a {
      font-size: 14px;
      color: #8B8D97;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s; }
      .main-header .main-nav a:hover, .main-header .main-nav a.active {
        color: #fff; }
  .main-header .user-header {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .main-header .user-header .icon-menu {
      margin-left: 10px; }

.icon-menu {
  cursor: pointer; }
  .icon-menu span {
    width: 16px;
    height: 3px;
    background: #8B8D97;
    display: block;
    border-radius: 4px;
    margin-bottom: 3px;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
    .icon-menu span:nth-child(2n) {
      width: 15px; }
  .icon-menu:hover span {
    background: #fff; }

.main-menu {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  z-index: 999; }
  .main-menu .content {
    width: 240px;
    height: 100%;
    position: relative;
    background: #292D41;
    padding: 30px;
    box-sizing: border-box;
    border-left: solid 1px #333;
    right: -240px;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    box-shadow: -8px 0px 20px rgba(41, 45, 65, 0.2); }
  .main-menu .icon-menu {
    float: right; }
  .main-menu .user {
    text-align: center;
    margin-bottom: 32px;
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .main-menu .user img {
      height: 25px;
      width: 25px;
      margin-right: 16px; }
    .main-menu .user p {
      color: #fff;
      font-size: 14px; }
      .main-menu .user p small {
        font-size: 12px;
        color: #8B8D97; }
  .main-menu nav {
    margin-bottom: 32px;
    min-width: 150px; }
    .main-menu nav a {
      display: block;
      font-size: 12px;
      color: #fff;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: solid 1px #4C5062;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s;
      text-align: left; }
      .main-menu nav a:last-child {
        border-bottom: none; }
      .main-menu nav a:hover, .main-menu nav a.router-link-exact-active, .main-menu nav a.router-link-active {
        color: #3498DB; }
  .main-menu .logout {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s; }
    .main-menu .logout p {
      color: #fff;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s;
      font-size: 14px; }
    .main-menu .logout:hover {
      background: #393e58;
      padding: 10px; }
      .main-menu .logout:hover p {
        color: #f39c12; }
  .main-menu.ativo {
    opacity: 1;
    visibility: visible; }
    .main-menu.ativo .content {
      right: 0; }

.popupbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000; }

.popupbox[hidden] {
  display: none; }

.popupbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background: none repeat scroll 0% 0% #000; }

.popupbox-content {
  overflow: hidden; }

.popupbox-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  z-index: 1003;
  position: relative; }

.popupbox-titleBar {
  overflow: hidden;
  display: block;
  position: relative; }

.popupbox-btn--close {
  z-index: 1004; }

.popupbox[data-title='bottom'] .popupbox-content {
  order: 1; }

.popupbox[data-title='bottom'] .popupbox-titleBar {
  order: 2; }

/* ----- default theme ----- */
.popupbox-wrapper {
  border-radius: 3px;
  overflow: hidden;
  max-width: 80%;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  background-color: white; }

.popupbox-titleBar {
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
  padding: 11px 37px 11px 24px;
  border-bottom: 1px #ccc solid; }

.popupbox-btn--close {
  -webkit-transition: all .5s;
  transition: all .5s;
  position: absolute;
  right: 11px;
  top: 11px;
  color: #c1c1c1;
  background: none;
  border: none;
  outline: none; }

.popupbox-btn--close:hover {
  color: #000; }

.popupbox.is-active {
  opacity: 1;
  pointer-events: auto; }

.popupbox[data-title='bottom'] .popupbox-content {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); }

.popupbox[data-title='bottom'] .popupbox-titleBar {
  box-shadow: none;
  border-top: 1px #ccc solid; }

.content-regerencial .list-rege .headerlist {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .content-regerencial .list-rege .headerlist p {
    font-size: 14px; }
  .content-regerencial .list-rege .headerlist .first {
    display: flex;
    padding-left: 40px; }
    .content-regerencial .list-rege .headerlist .first p {
      text-align: left; }
      .content-regerencial .list-rege .headerlist .first p:first-child {
        width: 200px; }
  .content-regerencial .list-rege .headerlist .last {
    display: flex; }
    .content-regerencial .list-rege .headerlist .last p {
      display: flex;
      align-items: center; }
    .content-regerencial .list-rege .headerlist .last span {
      width: 11px;
      height: 11px;
      display: flex;
      margin-right: 10px;
      margin-left: 5px; }
      .content-regerencial .list-rege .headerlist .last span.n {
        background: #1ABC9C; }
      .content-regerencial .list-rege .headerlist .last span.y {
        background: #E99898; }
      .content-regerencial .list-rege .headerlist .last span.a {
        background: #d25b5b; }

.content-regerencial .list-rege .item {
  background: #fff;
  border-radius: 6px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-left: solid 10px #1ABC9C; }
  .content-regerencial .list-rege .item .main-info {
    width: 140px;
    text-align: left;
    padding-right: 20px; }
    .content-regerencial .list-rege .item .main-info p {
      line-height: 18px; }
      .content-regerencial .list-rege .item .main-info p.emp {
        font-size: 10px;
        color: #FBA633;
        text-transform: uppercase; }
      .content-regerencial .list-rege .item .main-info p.idpro {
        font-size: 14px;
        color: #4C5062; }
      .content-regerencial .list-rege .item .main-info p.namepro {
        font-size: 16px;
        font-weight: 700;
        color: #4C5062;
        word-break: break-all; }
  .content-regerencial .list-rege .item .info {
    display: grid;
    grid-template-columns: 7fr 1fr;
    align-items: center;
    width: calc(100% - 160px);
    padding-left: 40px;
    border-left: solid 1px #8B8D97; }
    .content-regerencial .list-rege .item .info .list-gra {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    .content-regerencial .list-rege .item .info .item-gra {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px 30px 10px 0; }
      .content-regerencial .list-rege .item .info .item-gra:last-child {
        margin-right: 0; }
      .content-regerencial .list-rege .item .info .item-gra p {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        color: #4C5062; }
        .content-regerencial .list-rege .item .info .item-gra p img {
          margin-right: 10px; }
        .content-regerencial .list-rege .item .info .item-gra p:last-child {
          margin-bottom: 0; }
      .content-regerencial .list-rege .item .info .item-gra.alert {
        border-left: solid 4px #d25b5b;
        padding-left: 10px;
        padding: 10px;
        background: #f7e9e9;
        border-radius: 10px; }
    .content-regerencial .list-rege .item .info p {
      font-size: 14px; }
    .content-regerencial .list-rege .item .info .item-total {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px 30px 10px 0; }
      .content-regerencial .list-rege .item .info .item-total:last-child {
        margin-right: 0; }
      .content-regerencial .list-rege .item .info .item-total p {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        color: #4C5062; }
        .content-regerencial .list-rege .item .info .item-total p img {
          margin-right: 10px; }
        .content-regerencial .list-rege .item .info .item-total p:last-child {
          margin-bottom: 0; }
      .content-regerencial .list-rege .item .info .item-total.alert {
        border-left: solid 4px #d25b5b;
        padding-left: 10px;
        padding: 10px;
        background: #f7e9e9;
        border-radius: 10px; }
  .content-regerencial .list-rege .item.urgente {
    border-left: solid 10px #E99898; }

@media (max-width: 620px) {
  .content-regerencial .list-rege .headerlist {
    display: none; }
  .content-regerencial .list-rege .item {
    flex-wrap: wrap;
    padding: 20px; }
    .content-regerencial .list-rege .item .main-info {
      width: 100%;
      border-bottom: solid 1px #ccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
    .content-regerencial .list-rege .item .info {
      padding-left: 0;
      border: 0;
      display: flex;
      width: 100%;
      flex-wrap: wrap; }
      .content-regerencial .list-rege .item .info .item-gra {
        margin: 10px; }
      .content-regerencial .list-rege .item .info .list-gra {
        border-bottom: solid 1px #ccc;
        padding-bottom: 10px;
        margin-bottom: 10px; } }

.content-regerencial .page-interna .title {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 10px #1ABC9C;
  margin-bottom: 40px;
  box-sizing: border-box; }
  .content-regerencial .page-interna .title .first {
    display: flex;
    align-items: center; }
  .content-regerencial .page-interna .title p {
    font-size: 16px;
    margin-right: 60px;
    line-height: 20px; }
    .content-regerencial .page-interna .title p:nth-child(2) strong {
      font-size: 22px; }
    .content-regerencial .page-interna .title p.emp {
      color: #FBA633;
      text-transform: uppercase; }
  @media (max-width: 620px) {
    .content-regerencial .page-interna .title {
      flex-wrap: wrap;
      padding: 20px; }
      .content-regerencial .page-interna .title p {
        margin-bottom: 10px; }
        .content-regerencial .page-interna .title p strong {
          display: block; }
      .content-regerencial .page-interna .title .first {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px; }
        .content-regerencial .page-interna .title .first p {
          width: 100%;
          margin-right: 0; }
      .content-regerencial .page-interna .title .last p {
        margin: 0; } }

.content-regerencial .page-interna .boxgra {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  box-sizing: border-box; }
  .content-regerencial .page-interna .boxgra .first {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .content-regerencial .page-interna .boxgra .tit {
    display: flex;
    align-items: center;
    margin-right: 80px; }
    .content-regerencial .page-interna .boxgra .tit p {
      font-size: 16px; }
    .content-regerencial .page-interna .boxgra .tit img {
      margin-right: 10px; }
  .content-regerencial .page-interna .boxgra .item-gra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 30px 10px 0; }
    .content-regerencial .page-interna .boxgra .item-gra:last-child {
      margin-right: 0; }
    .content-regerencial .page-interna .boxgra .item-gra p {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      font-size: 14px; }
      .content-regerencial .page-interna .boxgra .item-gra p img {
        margin-right: 10px; }
      .content-regerencial .page-interna .boxgra .item-gra p:last-child {
        margin-bottom: 0; }
  .content-regerencial .page-interna .boxgra .total {
    display: flex;
    font-size: 16px;
    align-items: center; }
    .content-regerencial .page-interna .boxgra .total img {
      margin-right: 10px; }
  @media (max-width: 620px) {
    .content-regerencial .page-interna .boxgra {
      flex-wrap: wrap;
      padding: 20px; }
      .content-regerencial .page-interna .boxgra .first {
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ccc; }
        .content-regerencial .page-interna .boxgra .first .tit {
          width: 100%;
          padding-bottom: 10px;
          margin: 0 0 10px 0;
          border-bottom: solid 1px #ccc; } }

.content-regerencial .page-interna .list-po {
  margin: 60px 0 0;
  text-align: left; }
  .content-regerencial .page-interna .list-po p {
    font-size: 14px; }
  .content-regerencial .page-interna .list-po .header {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
    width: 100%;
    box-sizing: border-box;
    padding-left: 80px;
    margin-bottom: 20px; }
  .content-regerencial .page-interna .list-po .item {
    padding: 30px 0;
    padding-left: 80px;
    border-radius: 6px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAfCAYAAAAWRbZDAAAABHNCSVQICAgIfAhkiAAAAdpJREFUSInVlr2qE1EUhb91xvhD4CJ2qawmP4WKYmElVhcs9AX0CRQRH0EbuTbaiIKFtZWP4BVBsLKTJBPholySwsIYuCrOnG2TSDCTyUxm/LmrO3vtOR+ctdmMWKIoija993eAU8DhZX15JWlLaUa/3z9vZi/LAub0vtlstlyaY2b3KgQh6bGkZAFmZoGZnakSZmZvARZgw+HwkKRalbAgCL6mwv6kDqzwPznnrgLffze89+eAu5XBzGwjSZKLkj6neKel1GFeDybpIHBziVcIBPslsyyZWc17/0TS8dywrMxyaOGd185sJSkl0/8qs2/AAwpmBtSAa8DR3DAz25P0xjlXKLPpgFyRlB8m6Rjw3HtfhDX7dqG2PzKbrqvLlcGyMvPe7wLVwcpklqa/mtm/HZBGo/FjMpncLnKJmdUl3QKCTFgURRfmC4PBAOfcdl6Q9z5pt9uver3eJnAyE+a9f5H34jSZ2R5QB5JVvQ6wMjBNV4WZLR3ZmeeAXhkYcGQ0GtUlfcjoeQfgJD0qCWM8Hl9yzj1dYj8Lw/ALgAvD8CFwnxLPaWZbcRy/Bq4DH+fq2865G7Pzr9UcRdFGkiQngiBY6284juOdTqezA9Dtds9KGrZard35np8Mgb227t95FQAAAABJRU5ErkJggg==) #F5F5F5 no-repeat 20px center;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s; }
    .content-regerencial .page-interna .list-po .item.active, .content-regerencial .page-interna .list-po .item:hover {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAfCAYAAAAWRbZDAAAABHNCSVQICAgIfAhkiAAAAdpJREFUSInVlr2qE1EUhb91xvhD4CJ2qawmP4WKYmElVhcs9AX0CRQRH0EbuTbaiIKFtZWP4BVBsLKTJBPholySwsIYuCrOnG2TSDCTyUxm/LmrO3vtOR+ctdmMWKIoija993eAU8DhZX15JWlLaUa/3z9vZi/LAub0vtlstlyaY2b3KgQh6bGkZAFmZoGZnakSZmZvARZgw+HwkKRalbAgCL6mwv6kDqzwPznnrgLffze89+eAu5XBzGwjSZKLkj6neKel1GFeDybpIHBziVcIBPslsyyZWc17/0TS8dywrMxyaOGd185sJSkl0/8qs2/AAwpmBtSAa8DR3DAz25P0xjlXKLPpgFyRlB8m6Rjw3HtfhDX7dqG2PzKbrqvLlcGyMvPe7wLVwcpklqa/mtm/HZBGo/FjMpncLnKJmdUl3QKCTFgURRfmC4PBAOfcdl6Q9z5pt9uver3eJnAyE+a9f5H34jSZ2R5QB5JVvQ6wMjBNV4WZLR3ZmeeAXhkYcGQ0GtUlfcjoeQfgJD0qCWM8Hl9yzj1dYj8Lw/ALgAvD8CFwnxLPaWZbcRy/Bq4DH+fq2865G7Pzr9UcRdFGkiQngiBY6284juOdTqezA9Dtds9KGrZard35np8Mgb227t95FQAAAABJRU5ErkJggg==) #fff no-repeat 20px center;
      box-shadow: 1px 7px 0px 0px rgba(0, 0, 0, 0.1); }
  @media (max-width: 520px) {
    .content-regerencial .page-interna .list-po .header {
      justify-content: space-around; }
      .content-regerencial .page-interna .list-po .header .w60, .content-regerencial .page-interna .list-po .header .w20 {
        width: auto;
        padding: 0; }
    .content-regerencial .page-interna .list-po .item {
      flex-wrap: wrap;
      padding: 20px; }
      .content-regerencial .page-interna .list-po .item .w60, .content-regerencial .page-interna .list-po .item .w20 {
        width: 100%;
        padding: 0;
        text-align: center;
        margin-bottom: 10px; }
        .content-regerencial .page-interna .list-po .item .w60:last-child, .content-regerencial .page-interna .list-po .item .w20:last-child {
          margin-bottom: 0; } }

.content-regerencial .page-interna .content-po {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: solid 1px #fff; }
  .content-regerencial .page-interna .content-po:last-child {
    border-bottom: none; }
  .content-regerencial .page-interna .content-po header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between; }
    .content-regerencial .page-interna .content-po header .gra {
      width: 30%;
      background: #fff;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      box-sizing: border-box; }
      .content-regerencial .page-interna .content-po header .gra p {
        font-size: 16px;
        color: #8B8D97; }
        .content-regerencial .page-interna .content-po header .gra p:first-child {
          color: #292D41; }
    .content-regerencial .page-interna .content-po header .historico {
      width: 65%;
      display: flex;
      align-items: center; }
      .content-regerencial .page-interna .content-po header .historico .hist-tit {
        text-align: right;
        position: relative;
        margin-right: 10px; }
        .content-regerencial .page-interna .content-po header .historico .hist-tit p {
          font-size: 16px;
          color: #292D41;
          line-height: 22px; }
          .content-regerencial .page-interna .content-po header .historico .hist-tit p.date {
            font-size: 12px;
            color: #8B8D97; }
      .content-regerencial .page-interna .content-po header .historico .boll {
        width: 20px;
        height: 20px;
        background: #3498DB;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .content-regerencial .page-interna .content-po header .historico .boll span {
          width: 6px;
          height: 6px;
          background: #fff;
          border-radius: 50%; }
      .content-regerencial .page-interna .content-po header .historico .infouser {
        background: #fff;
        border-radius: 6px;
        display: flex;
        align-items: flex-start;
        padding: 20px;
        flex: 1 1;
        margin-left: 20px;
        position: relative; }
        .content-regerencial .page-interna .content-po header .historico .infouser:before {
          content: '';
          left: -10px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 10px 0;
          border-color: transparent #ffffff transparent transparent; }
        .content-regerencial .page-interna .content-po header .historico .infouser img {
          width: 30px;
          margin-right: 10px; }
        .content-regerencial .page-interna .content-po header .historico .infouser p {
          font-size: 14px;
          color: #292D41; }
          .content-regerencial .page-interna .content-po header .historico .infouser p.user {
            color: #707385;
            margin-bottom: 10px; }
  .content-regerencial .page-interna .content-po .boxs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .content-regerencial .page-interna .content-po .boxs .box {
      width: 30%;
      background: #fff;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 6px; }
      .content-regerencial .page-interna .content-po .boxs .box .icon {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px; }
        .content-regerencial .page-interna .content-po .boxs .box .icon img, .content-regerencial .page-interna .content-po .boxs .box .icon p {
          margin-right: 20px; }
        .content-regerencial .page-interna .content-po .boxs .box .icon:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #000; }
      .content-regerencial .page-interna .content-po .boxs .box .info .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px; }
        .content-regerencial .page-interna .content-po .boxs .box .info .row:last-child {
          margin-bottom: 0; }
        .content-regerencial .page-interna .content-po .boxs .box .info .row p {
          font-size: 14px;
          color: #292D41; }
          .content-regerencial .page-interna .content-po .boxs .box .info .row p:last-child {
            text-align: right;
            width: 45%;
            color: #8B8D97;
            font-size: 12px; }
  .content-regerencial .page-interna .content-po .box-transportadora {
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 6px; }
    .content-regerencial .page-interna .content-po .box-transportadora .tit {
      margin-bottom: 20px; }
      .content-regerencial .page-interna .content-po .box-transportadora .tit span {
        color: #8B8D97; }
  .content-regerencial .page-interna .content-po .line-status {
    position: relative;
    width: 100%; }
    .content-regerencial .page-interna .content-po .line-status:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 88%;
      height: 2px;
      background: #C8CFD4; }
    .content-regerencial .page-interna .content-po .line-status .position {
      width: 88%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      -webkit-margin-after: 10px;
              margin-block-end: 10px;
      overflow: hidden; }
      .content-regerencial .page-interna .content-po .line-status .position .boll {
        width: 20px;
        height: 20px;
        background: #ff9a27;
        border-radius: 50%;
        position: relative;
        -webkit-transition: all ease .3s;
        transition: all ease .3s; }
        .content-regerencial .page-interna .content-po .line-status .position .boll:before {
          content: '';
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 500px;
          height: 2px;
          background: #ff9a27; }
        .content-regerencial .page-interna .content-po .line-status .position .boll:after {
          content: '';
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 10px;
          height: 10px;
          background: #fff;
          border-radius: 50%;
          z-index: 2; }
        .content-regerencial .page-interna .content-po .line-status .position .boll.atual {
          width: 30px;
          height: 30px; }
        .content-regerencial .page-interna .content-po .line-status .position .boll.atual ~ .boll {
          background: #C8CFD4;
          width: 20px;
          height: 20px; }
          .content-regerencial .page-interna .content-po .line-status .position .boll.atual ~ .boll:before {
            display: none; }
        .content-regerencial .page-interna .content-po .line-status .position .boll:nth-child(1):before {
          display: none; }
    .content-regerencial .page-interna .content-po .line-status .legenda {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .content-regerencial .page-interna .content-po .line-status .legenda p {
        font-size: 12px;
        color: #8B8D97;
        width: 14%;
        text-align: center; }
  @media (max-width: 640px) {
    .content-regerencial .page-interna .content-po header {
      flex-wrap: wrap; }
      .content-regerencial .page-interna .content-po header .gra {
        width: 100%;
        margin-bottom: 20px; }
      .content-regerencial .page-interna .content-po header .historico {
        width: 100%; }
    .content-regerencial .page-interna .content-po .boxs {
      flex-wrap: wrap; }
      .content-regerencial .page-interna .content-po .boxs .box {
        padding: 20px;
        width: 100%;
        margin-bottom: 10px; } }

.content-regerencial .page-interna .panel {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out; }

.content-regerencial .alterada {
  color: #d25b5b !important;
  font-weight: 700; }

.description {
  font-size: 16px;
  margin-bottom: 40px; }

.list-alerts p {
  font-size: 14px;
  line-height: 20px; }

.list-alerts .header {
  padding: 10px 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1.5fr 1.5fr 3fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px; }
  @media (max-width: 620px) {
    .list-alerts .header {
      grid-template-columns: 1fr; } }

.list-alerts .item {
  background: #f7f7f7;
  padding: 10px 20px;
  margin-bottom: 4px;
  font-size: 14px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1.5fr 1.5fr 3fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px; }
  @media (max-width: 620px) {
    .list-alerts .item {
      grid-template-columns: 1fr; } }
  .list-alerts .item .date.altered {
    color: red; }
  .list-alerts .item .date.current {
    color: #666; }

.list-ope p {
  font-size: 12px;
  padding: 0 5px;
  color: #4C5062; }

.list-ope .header-list-ope {
  display: grid;
  grid-template-columns: .6fr 2fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
  margin-bottom: 20px; }
  .list-ope .header-list-ope p {
    font-size: 14px; }
  @media (max-width: 740px) {
    .list-ope .header-list-ope {
      grid-template-columns: .6fr 2fr 2fr  2fr 2fr 1fr; }
      .list-ope .header-list-ope .produto, .list-ope .header-list-ope .descricao, .list-ope .header-list-ope .qtd, .list-ope .header-list-ope .pd {
        display: none; } }

.list-ope .item {
  background: #fff;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 0;
  -webkit-transition: .2s;
  transition: .2s;
  display: grid;
  grid-template-columns: .6fr 2fr 2fr 4fr 2fr 2fr 2fr 2fr 2fr 2fr;
  height: auto;
  transition: .2s; }
  .list-ope .item:hover {
    box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.1); }
  .list-ope .item span.critico {
    border-left: solid 10px #1ABC9C;
    display: block;
    border-radius: 6px 0 0 6px;
    height: 100%;
    padding: 15px 0; }
  .list-ope .item .status {
    position: relative;
    display: flex;
    align-items: center; }
    .list-ope .item .status:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #1ABC9C;
      display: block;
      margin-right: 10px; }
    .list-ope .item .status.alert:before {
      background: #F3D641; }
    .list-ope .item .status.danger:before {
      background: #d25b5b; }
    @media (max-width: 740px) {
      .list-ope .item .status p {
        display: none; } }
  .list-ope .item .icon-justificativa {
    cursor: pointer;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAWFJREFUKJGdksFt20AQRd8f8x514KSC0BWYHYjpQLQbyEWADPlgICCswJc04FCpIFIFYQehOrA6kG/RQftzMCgIQoLI+afFzrz5fxYrjjSpH0dODEGFxMBmA25DWtzfXn077FV/GNdNHnYjyA1L2V1fs5QLhoZOZ/owu6me9vC4bvJI/gFep4jRw7TqONJLT5qDzpXpYnZTPQXAy6XX210UfwIBHqZVt91FAV575+8AmtSPI6xmJ138DTxOcGb/RK7CVmlYngL2CQxLW2WAi8PHOUWyO8xlCL15DbgfIAYBXv8PbPycGXUSl68CpRxoQ/ICKCb3zdtTwHHd5IKh5EWk5A3Ary2bf4Ef75pB2I3t1Wx6Pc9EFLZXX+6uNr17//2OHcNuwO+URQ6gyaevreG9xAboo7dI7X7HlHJJpe2Vsij74RnhuZJGNp1JbYQGtko5VaBz42ekFrn6fHs9P0zzG6XVto12yQO2AAAAAElFTkSuQmCC) no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 10px; }
    .list-ope .item .icon-justificativa.ativa {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAUpJREFUKJGd079N61AcxfHvuUIOHUnjlrwJnpkAbxCzQdwEeSJLbhImIJkAb4CzAelQmpgSCh+KmMBDoBf4lVfnI537T3yabVlOLU2AFBgCLVBLWsbX1zcfszqgqkrcdXMgAVbYzXtKCTABmsi+GhXFwwH38A7YKIRpPJs1fJo+swDOI/tiVBQPAaBf3ESDQfoVBIhnsyYaDFJg8yLdAqjf41whXHwHv2hwLzsPljJgdQx8awCsLGUBSP85nGNmn78MwNmP4PsMg+3NL/HTiaTGcPkjtr/3OsheCtJdWY6PcduqSoCJ7GVwCC0Ap6ft/+BuPh/2r3AdF8Ui2E6B9SjP211Zjr9rsK2q5OX5+Q74E9kZgB7Lspb019AKxgDef4T6IO0EyIB1ZGdvb/skwKKzp0gNUq2uGyJl7rpc0jnwBNSy87goFh/bvAKiG6F76MpyFQAAAABJRU5ErkJggg==) no-repeat; }
    .list-ope .item .icon-justificativa.ok {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAWVJREFUKJGdkkFq21AQhr9/nryuNgZ3lfQEVU4QmZRsrd7AIiWHcglyThB76xKsG1S+QbwzZKNsQiHmTRe1gjEpdfqvhjfzvflnGHGgwf3NWO4jQQ6kQOtQO8w2X77d7teqC/qLSdaTKqTM3eeCpss5ZJJGuDcxSb5uhuXDK9xfTLKe2RJYv8Q4fry8bjjQrmYKnMQQzjbD8sEAdo/r5xDyt0CAx8vr5jmEHFjbdnsHoMH9zdjcq5cYz/4GvuHgZ5RKU4yFu8+PATsH7j5XjIUJ8v3lHCNBI+nckD68B9xTag7r/0LdnxLcG5fO38VBBtTmZjNBPlhWp8eA/cUkkzRys5mZewvwC9p/gemySntSBaw2F1fTxP9se9UOy7br3p3fYcfedlsBn2IIGYA+/vheS/rs0ApOdzPVkurXGd0zQQGsYghF93niZlNiHCM1QB2lVDEWDqXgBPcnoI5m5ebiarrv5jfheKEQ6J3K6wAAAABJRU5ErkJggg==) no-repeat; }
  @media (max-width: 740px) {
    .list-ope .item {
      grid-template-columns: .6fr 2fr 2fr  2fr 2fr 1fr; }
      .list-ope .item .produto, .list-ope .item .descricao, .list-ope .item .qtd, .list-ope .item .pd {
        display: none; }
      .list-ope .item p {
        font-size: 10px; } }

.lb-justificativa {
  width: 100%;
  display: flex;
  margin-bottom: 40px; }
  .lb-justificativa h2 {
    font-size: 22px;
    margin-bottom: 40px; }
  .lb-justificativa .content {
    width: 70%;
    background: #fff;
    padding: 40px 60px; }
  .lb-justificativa .wrap-btns {
    width: 30%;
    background: #ccc;
    position: relative;
    padding: 40px 0; }
    .lb-justificativa .wrap-btns .btnclose {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4B4E60;
      color: #fff;
      cursor: pointer;
      font-size: 14px; }
  .lb-justificativa .form-just label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px; }
  .lb-justificativa .form-just input, .lb-justificativa .form-just select, .lb-justificativa .form-just textarea {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border: 0;
    border: solid 1px #C2C2C2;
    box-sizing: border-box;
    border-radius: 6px; }
  .lb-justificativa .form-just textarea {
    height: 100px;
    resize: none;
    outline: none;
    padding: 20px; }
  .lb-justificativa .form-just .row {
    display: grid;
    margin-bottom: 20px; }
    .lb-justificativa .form-just .row.c2 {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px; }
    .lb-justificativa .form-just .row .item {
      width: 100%; }
  .lb-justificativa .form-just .btn {
    height: 50px; }
    .lb-justificativa .form-just .btn.cancel {
      margin-left: 20px; }
  .lb-justificativa .form-just .erro {
    color: #FF4E50;
    font-size: 12px;
    margin: 5px 0; }
  .lb-justificativa .list-justificativas .item {
    border-bottom: solid 1px #292D41;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .lb-justificativa .list-justificativas .item:last-child {
      border-bottom: none; }
    .lb-justificativa .list-justificativas .item p {
      font-size: 14px;
      color: #292D41;
      line-height: 20px;
      margin-bottom: 20px; }
    .lb-justificativa .list-justificativas .item .user {
      display: flex;
      align-items: center; }
      .lb-justificativa .list-justificativas .item .user p {
        font-weight: bold;
        margin-right: 20px;
        margin-bottom: 0; }
        .lb-justificativa .list-justificativas .item .user p:last-child {
          margin-right: 0; }
      .lb-justificativa .list-justificativas .item .user input[type="checkbox"] {
        width: 18px;
        height: 18px;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        padding: 0;
        border: solid 2px #dac9c9;
        background-position: center;
        cursor: pointer;
        margin-right: 20px; }
        .lb-justificativa .list-justificativas .item .user input[type="checkbox"]:checked {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABHNCSVQICAgIfAhkiAAAAHRJREFUGJVjYCAAGHFJaOra1zL8Z4pnwinJwNT0n4HhBSNM5Q8GJuv7V/a+hEv+Zzj6h4ExngVqkTLH/39HNbQdNzIwMBTBJO9c2XeX+c2rh4dExeX/MTAyBjMyMlgiSzIwMDAwMzAwMMAU/f/PyIYsSRQAAFKINuzWI84RAAAAAElFTkSuQmCC) no-repeat center;
          border: solid 2px #292D41; }
  .lb-justificativa .wrap-btns {
    background: #292D41;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .lb-justificativa .wrap-btns button {
      width: 120px;
      height: 50px;
      margin-bottom: 10px;
      -webkit-transition: all ease 0.1s;
      transition: all ease 0.1s; }
      .lb-justificativa .wrap-btns button.abonar {
        margin-bottom: 60px;
        background: #1AB1BC; }
      .lb-justificativa .wrap-btns button:hover {
        background: transparent;
        border: solid 1px #fff; }

.list-planilhas {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  .list-planilhas .item {
    font-size: 14px;
    padding: 10px 20px;
    border: solid 1px #ddd;
    margin: 0 10px;
    border-radius: 6px;
    -webkit-transition: .2s;
    transition: .2s;
    cursor: pointer; }
    .list-planilhas .item:hover, .list-planilhas .item.active {
      background: #1abc9c;
      border: solid 1px #1abc9c;
      color: #fff; }

.box-import input {
  border-radius: 6px;
  background: #ddd;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box; }

.box-import .box-drop {
  height: 300px;
  width: 600px;
  border: solid 1px #ddd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px; }
  .box-import .box-drop p {
    font-size: 16px; }

